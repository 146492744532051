import React from 'react';

export default function Info() {
  return (
    <div>
      <h1>Información</h1>
      <p>Detalles sobre la aplicación...</p>
    </div>
  );
}
