import React, { useEffect } from 'react';
import privacyImage from './img/main/secure.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function Home() {
  useEffect(() => {
    const handleLoad = () => {
      document.getElementById("loader").classList.toggle("loader2");
    };

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div>
      <div className="loader" id="loader">
        <div className="lds-ripple background">
          <div></div>
          <div></div>
        </div>
      </div>

      {/* Cabecera */}
      <header className="header_area sticky-header">
        <div className="main_menu">
          <nav className="navbar navbar-expand-lg bg-light main_box" id="navbar">
            <div className="container">
              <a className="navbar-brand logo_h" href="/"><img src="https://darimo.com.co/image/logo/logo.ico"
                width="18%" alt="logo" /></a>
              <button className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                <ul className="nav navbar-nav menu_nav ml-auto">
                  <li className="nav-item active"><a className="nav-link" href="/">Inicio</a></li>
                  <li className="nav-item submenu dropdown">
                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button"
                      aria-haspopup="true" aria-expanded="false">Aplicaciones</a>
                    <ul className="dropdown-menu">
                      <li className="nav-item"><a className="nav-link" href="/darimo">Darimo</a></li>
                      <li className="nav-item"><a className="nav-link" href="/jornal">Jornal</a></li>
                      <li className="nav-item"><a className="nav-link" href="/jornal">Rody</a></li>
                    </ul>
                  </li>
                  <li className="nav-item"><a className="nav-link" href="/seguridad">Mis datos</a></li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {/* Fin Cabecera */}

      <section className="banner-area">
        <div className="container">
          <div className="row fullscreen align-items-center justify-content-start">
            <div className="col-lg-12">
              <div className="active-banner-slider">
                <div className="row single-slide align-items-center d-flex">
                  <div className="col-lg-6 col-md-6">
                    <div className="banner-content">
                      <h1>Politícas de privacidad</h1>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner-img">
                      <img className="img-fluid rounded" src={privacyImage} alt="Privacidad y seguridad - undraw" width="80%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-area section_gap">
        <div className="container">
          <div className="row features-inner">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-features">
                <div className="f-icon">
                  <img src="https://darimo.com.co/img/features/f-icon3.png" alt="" />
                </div>
                <h6>Soporte</h6>
                <p>Mediante correo electrónico</p>
              </div>
            </div>
            <div className="col-lg-3¿6 col-md-6 col-sm-6">
              <div className="single-features">
                <div className="f-icon">
                  <img src="https://darimo.com.co/img/features/f-icon4.png" alt="" />
                </div>
                <h6>Bases de datos</h6>
                <p>Seguras y con copias de seguridad diarias</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-area section_gap">
        <div className="container">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>¿Compartimos tu información?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                En cumplimiento de lo establecido en la Ley 1581 del 2012, conocida como la Ley de Protección de Datos Personales en Colombia, deseamos informarte sobre nuestra política respecto al manejo de tu información personal.
                <br />
                En ningún caso compartiremos tu información personal con terceros, a menos que estemos legalmente obligados a hacerlo por mandato de autoridad competente, o cuando sea necesario para cumplir con los fines específicos para los cuales has proporcionado tu consentimiento expreso.
                <br />
                Es importante destacar que estamos comprometidos con la protección de tu privacidad y la seguridad de tus datos personales. Todas las medidas necesarias son implementadas para garantizar la confidencialidad, integridad y disponibilidad de tu información, de acuerdo con lo dispuesto en la legislación colombiana vigente.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </section>

    </div>
  );
}
