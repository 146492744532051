import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Info from './Info';
import Contact from './Contact';
import './App.css';
import './css/bootstrap.min.css';
import './css/magnific-popup.css';
import './css/nouislider.min.css';
import './css/nice-select.css';
import './css/loader.css';
import './css/ion.rangeSlider.css';
import './css/ion.rangeSlider.skinFlat.css';
import './css/main.css';


function App() {

  useEffect(() => {
    document.title = "Inicio - Politicas de privacidad";
    const loadScripts = async () => {
      const scripts = [
        'https://darimo.com.co/js/vendor/jquery-2.2.4.min.js',
        'https://darimo.com.co/js/vendor/bootstrap.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js',
        'https://darimo.com.co/js/jquery.ajaxchimp.min.js',
        'https://darimo.com.co/js/jquery.nice-select.min.js',
        'https://darimo.com.co/js/jquery.sticky.js',
        'https://darimo.com.co/js/nouislider.min.js',
        'https://darimo.com.co/js/jquery.magnific-popup.min.js',
        'https://politicas.darimo.com.co/js/main.js',
        'https://darimo.com.co/js/preloader.js',
      ];

      for (const script of scripts) {
        const scriptElement = document.createElement('script');
        scriptElement.src = script;
        scriptElement.async = true;
        document.body.appendChild(scriptElement);
      }
    };

    loadScripts();

    // Cleanup function
    return () => {
      // Remove the scripts when the component unmounts
      const scripts = document.querySelectorAll('script[src^="https://darimo.com.co"]');
      scripts.forEach(script => {
        document.body.removeChild(script);
      });
    };
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/informacion" element={<Info />} />
          <Route path="/seguridad" element={<Info />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
